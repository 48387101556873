import React from "react";
import FaqPage from "./FaqPage/FaqPage";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

export default () => (
  <FaqPage/>
);
